import React from "react"

import styles from "./title-text.module.scss"

function TitleText(props) {
  return (
    <div className={styles.container}>
      <h2 className={styles.text}>{props.text}</h2>
    </div>
  )
}

TitleText.propTypes = {}

TitleText.defaultProps = {}

export default TitleText
